// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/Users/bundit/code/sawasdee/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dinner-menu-js": () => import("/Users/bundit/code/sawasdee/src/pages/dinner-menu.js" /* webpackChunkName: "component---src-pages-dinner-menu-js" */),
  "component---src-pages-index-js": () => import("/Users/bundit/code/sawasdee/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lunch-menu-js": () => import("/Users/bundit/code/sawasdee/src/pages/lunch-menu.js" /* webpackChunkName: "component---src-pages-lunch-menu-js" */)
}

